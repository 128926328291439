import Vue from "vue";
import { API } from "../../../services/API"
// import { maskCPFCNPJ, maskTELEFONE, CpfCnpjIsValid, emailIsValid, telefoneIsValid } from "../../services/funcoes";

function initialState() {
  return {
    dados: [],
    acao: "C", // E=Edicao, I=Inclusão, C=Consulta
    insumo_selecionado: {}, // Insumo Selecionada para Consulta
    insumo_edicao: {},  // Insumo Selecionado para Edição

    currentPage: 1,
    count_dados: null,

    // Filtro na Tela de Consulta 
    filtro: {
      cod_insumo: null,
      descricao: null,            
      unidade: null,
      categoria: null,
      tipo: null,
      base: null,
      valor_unitario_min: null,
      valor_unitario_max: null,            
      //estoque_min: null,
      //estoque_max: null,
   
    },
    // Abas do container 
    abas: [
      ['mdi-text-box-search-outline', 'Dados', '0'],
      ['mdi-archive-outline', 'Estoque', '0'],
      ['mdi-cash-multiple', 'Historico de Compras', '0'],
      //['mdi-timer-sand-complete', 'Cronograma', '0'],
    ],
  }
}

var vm_store_Insumo = new Vue({

  data() {
    return initialState();
  },

  methods: {

    resetData() {
      Object.assign(this.$data, initialState());
    },

    // Get de todos os insumos
    async InsumosGet(p_params) {
      const resp = await API.get("/insumos", { params: { ...p_params } });
      if (resp) {
        this.dados = [];
        if (resp.data.result !== 'Não possui dados') {
          this.dados = resp.data.result
          this.count_dados = resp.data.result.length;
          return resp.data.result
        }
      }
    },

    // Get de um insumo específico
    async InsumoGet(p_params) {
      const resp = await API.get(`/insumos/${p_params.cod_insumo}`);
      return resp.data.result
    },
    async InsumoPost(body) {
      const resp = await API.post(`/insumos`, JSON.stringify(body));
      return resp;
    },

    async InsumoPut(p_params) {
      const resp = await API.put(`/insumos/${p_params.cod_insumo}`, JSON.stringify(p_params))
      return resp;
    },

    async InsumoDelete(p_params) {
      const resp = await API.delete(`/insumos/${p_params.cod_insumo}`);
      return resp
    },

    async ObrasGet(p_params) {
      const resposta = await API.get("/obras", { params: { ...p_params } });
      if (resposta) {
        this.obras = resposta.data.result;
        return resposta.data.result;
      }
    }
  },

  watch: {
    // docDocumentos()           { this.atualiza_abas() },
    // docContrato()             { this.atualiza_abas() },
    // docTransferencia()        { this.atualiza_abas() },
    // docRenogociacao()         { this.atualiza_abas() },
    // docRescisao()             { this.atualiza_abas() },
    // docAutorizacaoEscritura() { this.atualiza_abas() },
    // docEscritura()            { this.atualiza_abas() },
    // docMensagens()            { this.atualiza_abas() }
  },


})

export default vm_store_Insumo
