import Vue from "vue";
import { API } from "../../../../services/API"

function initialState() {
    return {
        acao: "I", // E=Edicao, I=Inclusão C=Consulta
        dados: {},
        cadastro_insumo: {}, //Dados a serem alterados no cadastro/edicao do insumo
        transferir_insumo: {descricao: "Deposito Ana Maria", quantidade: 40 },
        dialog_cadastrar_insumo: false,
        dialog_transferir_insumo: false,
    }
}

var vm_store_ModalObra = new Vue({
    data() {
        return initialState();
    },

    methods: {
        resetData() {
            Object.assign(this.$data, initialState());
        },

        async InsumoPost(body) {
            const resp = await API.post(`/insumos`, JSON.stringify(body));
            return resp;
        },

        async InsumoPut(p_params) {
            const resp = await API.put(`/insumos/${p_params.cod_insumo}`, JSON.stringify(p_params))
            return resp;
        },
    }
})

export default vm_store_ModalObra